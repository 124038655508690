<template>
    <v-card
        class="py-10"
        outlined
        elevation="0"
        :style="{ borderColor: parameters.secondaryColor }"
    >
        <h2 class="font-weight-light pb-2">{{ confirmationUser.header }}</h2>
        <v-subheader class="justify-center subtitle-2"
            >{{ confirmationUser.subheader }}</v-subheader
        >
        <v-card-actions class="mt-4 justify-center">
            <v-btn outlined :color="parameters.secondaryColor" @click="close"
                >{{ confirmationUser.btnTxt }}</v-btn
            >
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        confirmationUser: {
            get() {
                return this.$store.state.confirmationUser;
            },
        },
    },
    methods:{
        close(){
            if (!this.confirmationUser.isError) {
                this.$router.push('/login')
            }
            this.$store.dispatch("confirmationUser/clear")
        }
    }
};
</script>

<style>
</style>